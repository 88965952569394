import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faList } from '@fortawesome/free-solid-svg-icons';

import logo from './c4s.svg';
import { ANTIBODY, CELLFILL } from './icon';
import './Header.css';


export default function Footer() {
  const buttonSX = {color: 'white', fontSize: "0.9em", marginLeft: "1em", marginRight: "0.5em"};

  return (
    <AppBar position="relative">
      <Toolbar>
        <Link href='/'>
          <img src={logo} className="header-c4s-logo" alt="logo" />
        </Link>
        <Typography variant="h6" sx={{flexGlow: 1}} />
        <Link href='/'>
          <Button sx={buttonSX}><FontAwesomeIcon icon={faHouse} /> Home</Button>
        </Link>
        <Link href='/experiment'>
          <Button sx={buttonSX}><FontAwesomeIcon icon={faList} /> Experiments</Button>
        </Link>
        <Link href='/target'>
          <Button sx={buttonSX}>{ANTIBODY} ChIP Targets</Button>
        </Link>
        <Link href='/samplesource'>
          <Button sx={buttonSX}>{CELLFILL} Sample Sources</Button>
        </Link>
      </Toolbar>
    </AppBar>
  );
}
