export const DNA = (
  <svg className="svg-inline--fa" x="0px" y="0px" viewBox="0 0 512 512">
    <g>
      <path d="M197.7,421L78,301.4c5.9-4.1,11.9-7.6,17.8-10.7c6.6-3.4,13.2-6.4,19.9-8.8L216,382.2c3.4-11,5.6-22.7,6.7-35.6l-73.3-73.4c6.9-1.1,13.9-1.8,21-2.3c16-1,32.6-0.4,49.8,1c-0.4-3.9-0.8-7.7-1.3-11.4c-1.1-8.9-2.1-18.3-3-28c-5.9-0.4-11.8-0.7-17.6-0.8c-30.1-0.9-60.4,1.6-90.5,11.8c-15,5.1-30,12-44.6,21.2c-14.6,9.2-29,20.7-43,34.7l-20,20l27.5,27.5l20-20c2.4-2.4,4.7-4.6,7-6.7l124.6,124.6C186.3,437,192.4,429.1,197.7,421z" />
      <path d="M270.5,197c-0.8-27.2,1.6-52.6,9.8-77c4.1-12.2,9.7-24.2,17.3-36.3c7.6-12,17.2-24.1,29.3-36.3l20-20L319.4,0l-20,20c-18.7,18.7-32.9,38-43.2,57.8c-15.5,29.6-22.3,60.1-24.1,90.3c-1.8,30.2,1,60.2,4.6,90.3c2.4,19.6,4.3,38.5,4.8,56.6c0.8,27.2-1.5,52.6-9.8,77c-4.1,12.2-9.7,24.3-17.3,36.3c-7.6,12-17.2,24.1-29.3,36.2l-20,20l27.5,27.5l20-20c18.7-18.7,32.9-38,43.2-57.8c15.5-29.6,22.3-60.1,24.1-90.3c1.8-30.2-1-60.2-4.6-90.3C273,234.1,271.1,215.2,270.5,197z" />
      <path d="M484.5,165.1l-20,20c-2.4,2.4-4.7,4.6-7,6.7L332.9,67.2c-7.1,7.8-13.2,15.6-18.6,23.8L434,210.7c-5.9,4-11.9,7.6-17.8,10.7c-6.6,3.5-13.2,6.4-19.9,8.8L296,129.8c-3.4,11-5.6,22.7-6.7,35.6l73.4,73.4c-6.9,1.1-13.9,1.8-21.1,2.3c-16,1-32.6,0.5-49.8-1c0.4,3.9,0.9,7.7,1.3,11.4c1.1,8.9,2.1,18.3,3,28c5.9,0.4,11.8,0.7,17.7,0.9c30.1,0.8,60.4-1.7,90.5-11.8c15.1-5.1,30-12,44.6-21.2c14.6-9.2,29-20.7,43-34.7l20-20L484.5,165.1z" />
    </g>
  </svg>
);

export const CELL = (
  <svg className="svg-inline--fa" height="100px" width="100px" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100">
    <g>
      <path d="M62.9,94.9c-5.8,0-10.5-4.7-14.9-9.2c-3.3-3.3-6.7-6.8-10.5-8.3c-3.6-1.5-7.6-1.6-11.4-1.6l-3.2,0c0,0,0,0,0,0    c-5.8,0-13.6,0-16.9-8.4C2.9,60,8.2,54.3,13.3,48.7c3-3.3,6.1-6.7,7.4-10.3c1.4-4,1.6-8.4,1.8-12.7c0.3-7.9,0.7-16.1,9.9-19.8    c1.6-0.6,3.2-0.9,4.7-0.9c5.7,0,9.9,4.4,14,8.7c3.1,3.2,6.3,6.5,10.1,8.2c3.8,1.7,8.6,1.8,13.2,1.9c8,0.2,16.2,0.5,19.4,8.7    c3.8,9.6-3.1,13.8-9.7,17.8c-4.4,2.6-8.9,5.3-11.6,9.7c-1.9,3.1-1.3,9-0.6,14.6c0.9,8.2,1.8,16.7-4.6,19.3    C65.8,94.6,64.3,94.9,62.9,94.9z M26,72.8c3.9,0,8.5,0.1,12.6,1.8c4.4,1.8,8,5.5,11.5,9c4.2,4.3,8.2,8.3,12.8,8.3    c1.1,0,2.1-0.2,3.3-0.7c4.3-1.7,3.6-8.7,2.8-16.2c-0.7-6.2-1.4-12.6,1.1-16.5c3.1-5,8.2-8.1,12.6-10.7c7.2-4.4,11.2-7.1,8.5-14.1    c-2.5-6.4-9.1-6.6-16.7-6.8c-4.9-0.2-9.9-0.3-14.3-2.2c-4.4-1.9-7.8-5.5-11.1-8.9c-3.8-4-7.4-7.8-11.9-7.8c-1.2,0-2.3,0.2-3.6,0.7    c-7.4,2.9-7.7,9.2-8,17.1c-0.2,4.5-0.4,9.1-2,13.6c-1.5,4.2-4.8,7.8-8.1,11.3c-4.9,5.3-9.1,9.9-6.9,15.5c2.4,6,7.4,6.5,14.2,6.5    L26,72.8z" />
    </g>
    <g>
      <path d="M48.1,62.2c-6.7,0-12.2-5.5-12.2-12.2c0-6.7,5.5-12.2,12.2-12.2S60.2,43.3,60.2,50C60.2,56.7,54.8,62.2,48.1,62.2z    M48.1,40.8c-5.1,0-9.2,4.1-9.2,9.2s4.1,9.2,9.2,9.2s9.2-4.1,9.2-9.2S53.1,40.8,48.1,40.8z" />
      <path d="M39.3,32c-4.1,0-7.5-3-8.7-4.2c-0.2-0.1-0.3-0.3-0.4-0.3C29.5,27,29.4,26,30,25.4c0.5-0.6,1.5-0.7,2.1-0.2   c0.1,0.1,0.3,0.2,0.5,0.4c2.4,2.2,5.7,4.5,9.2,2.8c5.7-2.8,9.9-1.2,12,1.1c0.5,0.6,0.5,1.6-0.1,2.1c-0.6,0.5-1.6,0.5-2.1-0.1   c-0.5-0.5-3-3-8.4-0.4C41.8,31.8,40.5,32,39.3,32z" />
      <path d="M65.5,54.3c-0.7,0-1.4-0.5-1.5-1.3c-0.6-4.1,1.8-6.2,3.8-7.8c1.6-1.3,2.6-2.2,2.6-3.7c0.1-3-5.1-5-7-5.5   c-0.8-0.2-1.3-1-1.1-1.8c0.2-0.8,1-1.3,1.8-1.1c0.4,0.1,9.5,2.4,9.3,8.5c-0.1,2.9-2.1,4.6-3.7,5.9c-2,1.6-3.1,2.6-2.7,5   c0.1,0.8-0.4,1.6-1.3,1.7C65.6,54.3,65.6,54.3,65.5,54.3z" />
      <path d="M20.2,64.3c-0.3,0-0.7-0.1-1-0.4c-1.5-1.3-1-4.3,1.3-9.2c0.4-0.7,1.3-1.1,2-0.7c0.8,0.4,1.1,1.3,0.7,2   c-1.1,2.3-2,4.9-1.9,5.8c0.5,0.6,0.5,1.4,0,1.9C21,64.2,20.6,64.3,20.2,64.3z" />
      <path d="M26.4,65.8c-0.5,0-1-0.2-1.3-0.7c-1-1.5-1.3-2.8-1-4c0.4-1.3,1.4-1.8,1.6-1.9c0.8-0.3,1.7,0,2,0.8c0.3,0.7,0,1.6-0.7,1.9   c0,0.1-0.1,0.5,0.6,1.5c0.5,0.7,0.3,1.6-0.4,2.1C27,65.8,26.7,65.8,26.4,65.8z" />
    </g>
  </svg>
);

export const CELLFILL = (
  <svg className="svg-inline--fa" version="1.1" x="0px" y="0px" viewBox="0 0 100 100">
    <path d="M93.8,32.5c-3.2-8.2-11.4-8.5-19.4-8.7c-4.6-0.1-9.4-0.2-13.2-1.9c-3.8-1.7-7-5-10.1-8.2C47,9.4,42.8,5,37.1,5c-1.5,0-3.1,0.3-4.7,0.9c-9.2,3.7-9.6,11.9-9.9,19.8c-0.2,4.3-0.4,8.7-1.8,12.7c-1.3,3.6-4.4,7-7.4,10.3C8.2,54.3,2.9,60,6,67.4c3.3,8.4,11.1,8.4,16.9,8.4h3.2c3.8,0,7.8,0.1,11.4,1.6c3.8,1.5,7.2,5,10.5,8.3c4.4,4.5,9.1,9.2,14.9,9.2c1.4,0,2.9-0.3,4.4-1c6.4-2.6,5.5-11.1,4.6-19.3C71.2,69,70.6,63.1,72.5,60c2.7-4.4,7.2-7.1,11.6-9.7C90.7,46.3,97.6,42.1,93.8,32.5z M23.2,56c-1.1,2.3-2,4.9-1.9,5.8c0.5,0.6,0.5,1.4,0,1.9c-0.3,0.5-0.7,0.6-1.1,0.6c-0.3,0-1.7-0.6-2-0.9c-1.5-1.3-0.9-4.6,1.4-9.5c0.4-0.7,2.3-1,3-0.6C23.5,53.6,23.6,55.3,23.2,56z M28.1,66.7C27.9,67,27.6,67,27.3,67c-0.5,0-1.5-0.5-1.8-1c-1-1.5-1.7-3.6-1.4-4.8c0.4-1.3,1.4-1.8,1.6-1.9c0.8-0.3,2.2,0.3,2.5,1.1c0.3,0.7,0,1.6-0.7,1.9c0,0.1-0.1,0.5,0.6,1.5C28.6,64.4,28.8,66.2,28.1,66.7z M30.2,27.5c-0.7-0.5-0.8-2.2-0.2-2.8c0.5-0.6,1.3-1.1,1.9-0.6c0.1,0.1,0.3,0.2,0.5,0.4c2.4,2.2,5.7,4.5,9.2,2.8c5.7-2.8,9.9-1.2,12,1.1c0.5,0.6,1.1,2.2,0.5,2.7s-2,0.9-2.5,0.3c-0.5-0.5-3-3-8.4-0.4c-1.4,0.7-2.7,0.9-3.9,0.9c-4.1,0-7.5-3-8.7-4.2C30.4,27.7,30.3,27.5,30.2,27.5z M48.1,62.2c-6.7,0-12.2-5.5-12.2-12.2s5.5-12.2,12.2-12.2S60.2,43.3,60.2,50S54.8,62.2,48.1,62.2z M70.6,47.5c-2,1.6-3.1,2.6-2.7,5c0.1,0.8-0.5,1.6-1.4,1.8c-0.1,0.1-0.1,0.1-0.2,0.1c-0.7,0-2.2-0.5-2.3-1.3c-0.6-4.1,1.8-6.2,3.8-7.8c1.6-1.3,2.6-2.2,2.6-3.7c0.1-3-5.1-5-7-5.5c-0.8-0.2-1.3-1.4-1.1-2.2s1.8-1.7,2.6-1.5c0.4,0.1,9.3,2.3,9.1,8.4C73.8,43.7,72.2,46.2,70.6,47.5z" />
  </svg>
);

export const ANTIBODY = (
  <svg className="svg-inline--fa" x="0px" y="0px" viewBox="0 0 250 250">
    <g>
      <rect x="153.9" y="137.5" transform="matrix(0.9531 -0.3026 0.3026 0.9531 -34.7414 65.8624)" width="82.6" height="15" />
      <polygon points="106.3,108.7 126,19.2 140.7,22.4 120.2,115.8 27.4,217.5 16.3,207.3" />
      <rect x="54.2" y="46.5" transform="matrix(0.2146 -0.9767 0.9767 0.2146 22.2253 135.7553)" width="82.6" height="15" />
      <polygon points="139.8,139.3 227.2,111.6 222.6,97.2 131.5,126 38.7,227.8 49.8,237.9" />
    </g>
  </svg>
);

const C4S = (props: {color: string, className?: string}) => {
  const { color, className } = props;

  return (
    <svg className={className} x="0px" y="0px" width="82.307px" height="131.808px" viewBox="0 0 82.307 131.808">
      <g>
        <g>
          <path fill={color} d="M51.423,18.769c0,0-60.376,34.169-17.914,71.655C33.509,90.424,66.683,43.814,51.423,18.769z" />
          <path fill={color} d="M36.661,92.414c8.625,10.119,8.562,12.08,8.562,19.02s-3.495,14.154-11.278,14.154c-8.895,0-9.971-11.507-3.483-11.507c3.488,0,5.549,5.611,2.052,5.614c-1.997-0.161-1.286-2.504-2.363-2.504s-2.205,4.789,2.73,4.83c6.055,0,6.137-12.191-2.405-12.191c-12.44,0-10.968,21.978,3.158,21.978s18.868-11.941,18.868-24.568s-7.547-12.834,0-46.506C49.847,71.184,43.461,81.302,36.661,92.414z" />
          <path fill={color} d="M49.131,15.412c-1.461,1.426-17.446,12.313-20.245,16.339c-1.96,0-10.51-3.196-21.584,1.614c-4.246,1.845-3.794,1.244-5.411-0.933c-1.617-2.178-0.666-3.78,0-3.732c0.666,0.048,17.686-3.296,21.003-12.254C25.319,10.435,35.924,0.028,49.131,15.412z" />
        </g>
        <g>
          <rect x="63.061" y="55.235" transform="matrix(-0.6487 0.7611 -0.7611 -0.6487 156.2073 57.5885)" fill={color} width="3.5" height="19.231" />
          <polygon fill={color} points="61.039,42.821 81.137,35.681 82.307,38.979 61.365,46.439 29.843,40.632 30.478,37.19" />
          <rect x="60.886" y="32.209" transform="matrix(-0.9423 0.3348 -0.3348 -0.9423 148.3049 42.3496)" fill={color} width="19.231" height="3.5" />
          <polygon fill={color} points="59.129,53.198 75.36,67.03 77.631,64.366 60.722,49.933 29.2,44.126 28.565,47.567" />
        </g>
        <g>
          <polygon fill={color} points="43.915,9.494 52.501,4.67 50.964,2.002 44.352,5.675 44.353,1.062 41.274,1.06 41.258,7.957 37.382,14.648 40.045,16.189" />
        </g>
      </g>
    </svg>
  );
};

C4S.defaultProps = {
  color: "black"
};

export {C4S};
