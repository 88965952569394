import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from '@mui/material/styles';

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import Header from './base/Header';
import Footer from './base/Footer';
import { theme } from './Theme';
import './index.css';

//
const client = new ApolloClient({
  uri: 'https://api.test.c4s.site/v1/graphql',
  cache: new InMemoryCache()
});

//
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Suspense fallback={<div>Loading...</div>}>
          <BrowserRouter>
            <Header />
            <App />
            <Footer />
          </BrowserRouter>
        </Suspense>
      </ApolloProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
