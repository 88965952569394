import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://www.sb.ecei.tohoku.ac.jp/index-en.html">
        Kinoshita, Obayashi & Nishi Laboratory
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const AppFooter = styled('footer')(({ theme }) => ({
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800]
}));

export default function Footer() {
  return (
    <AppFooter>
      <Container maxWidth="sm">
        <Copyright />
      </Container>
    </AppFooter>
  );
}
