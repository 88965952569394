import React from 'react';

import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

const Home = React.lazy(() => import('./screens/Home'));
const ExperimentSet = React.lazy(() => import('./screens/dataset/Main'));
const Experiment = React.lazy(() => import('./screens/experiment/Main'));
const Alignment = React.lazy(() => import('./screens/alignment/Main'));
const Target = React.lazy(() => import('./screens/target/Main'));
const SampleSource = React.lazy(() => import('./screens/samplesource/Main'));
const Sample = React.lazy(() => import('./screens/sample/Main'));
const Gene = React.lazy(() => import('./screens/gene/Main'));

const Search = React.lazy(() => import('./screens/search/Main'));
const ExperimentSetSearch = React.lazy(() => import('./screens/search/dataset/Main'));
const ExperimentSearch = React.lazy(() => import('./screens/search/experiment/Main'));
// const AlignmentSearch = React.lazy(() => import('./screens/search/alignment/Main'));
const TargetSearch = React.lazy(() => import('./screens/search/target/Main'));
const SampleSourceSearch = React.lazy(() => import('./screens/search/samplesource/Main'));
const SampleSearch = React.lazy(() => import('./screens/search/sample/Main'));
// const GeneSearch = React.lazy(() => import('./screens/search/gene/Main'));


const RemoveTrailingSlash = ({...rest}) => {
    const location = useLocation();

    // If the last character of the url is '/'
    if (location.pathname.match('/.*/$')) {
        return <Navigate replace {...rest} to={{
            pathname: location.pathname.replace(/\/+$/, ""),
            search: location.search
        }}/>;
    } else return null;
};

const App: React.FC = () => {
  return (
    <main>
      <RemoveTrailingSlash/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dataset" element={<ExperimentSetSearch />} />
        <Route path="/experiment" element={<ExperimentSearch />} />
        <Route path="/target" element={<TargetSearch />} />
        <Route path="/samplesource" element={<SampleSourceSearch />} />
        <Route path="/sample" element={<SampleSearch />} />

        <Route path="/search" element={<Search />} />
        <Route path="/dataset/:external_id" element={<ExperimentSet />} />
        <Route path="/experiment/:external_id" element={<Experiment />} />
        <Route path="/alignment/:internal_id" element={<Alignment />} />
        <Route path="/target/:target_name" element={<Target />} />
        <Route path="/samplesource/:source_name" element={<SampleSource />} />
        <Route path="/Sample/:external_id" element={<Sample />} />
        <Route path="/gene/:species/:symbol" element={<Gene />} />
        <Route element={<p>not found!.</p>} />
      </Routes>
    </main>
  );
};

export default App;
