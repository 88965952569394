import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontSize: 12,
    h1: {fontSize: "3rem"},
    h2: {fontSize: "2.5rem"},
    h3: {fontSize: "2rem"},
    h4: {fontSize: "1.75rem"},
    h5: {fontSize: "1.5rem"},
    h6: {fontSize: "1.25rem"}
  }
});

export { theme };
